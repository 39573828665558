<template>
  <b-card
    no-body
    class="p-1"
  >
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <!--header-->
      <div
        class="demo-spacing-0 d-flex justify-content-between m-1"
        dir="rtl"
      >
        <!--      <b-button-->
        <!--        v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
        <!--        variant="primary"-->
        <!--        @click="isActive=true"-->
        <!--      >-->
        <!--        <feather-icon-->
        <!--          size="16"-->
        <!--          icon="FilterIcon"-->
        <!--        />-->
        <!--        فیلتر-->
        <!--      </b-button>-->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
          @click="$refs.modal.toggle()"
        >
          <feather-icon
            size="16"
            icon="DollarSignIcon"
          />
          قیمت جدید
        </b-button>
      </div>

      <!--addNewPriceModal-->
      <b-modal
        ref="modal"
        v-model="new_price_modal"
        cancel-variant="outline-secondary"
        ok-title="ثبت قیمت"
        cancel-title="لغو"
        centered
        title="قیمت جدید دلار"
        @ok.prevent="submit(new_price)"
      >
        <b-form @submit.prevent="submit(new_price)">
          <b-form-group label="ثبت قیمت روز دلار">
            <cleave
              v-model="new_price"
              class="form-control"
              :raw="false"
              :options="{numeral: true}"
              placeholder="به تومان"
            />
          </b-form-group>
        <!--                <b-form-group label="رمز دوم امنیتی">-->
        <!--                    <b-form-input-->
        <!--                            type="password"-->
        <!--                            placeholder="رمز"-->
        <!--                    />-->
        <!--                </b-form-group>-->
        </b-form>
      </b-modal>

      <!--priceTable-->
      <div class="relative-over-x">
        <b-table
          ref="refUserListTable"
          :items="items"
          :small="true"
          responsive
          :fields="columns"
          primary-key="id"
          show-empty
          empty-text="اطلاعاتی یافت نشد"
          no-local-sort
          class="text-nowrap"
          style="min-height : 235px"
          @sort-changed="sort($event)"
        >

          <template #cell(price)="data">
            <span dir="ltr">
              {{ Number(data.item.price).toLocaleString() }}
            </span>
          </template>

          <!--                    <template #cell(dollarDeals)="data">-->
          <!--                        <span dir="ltr">-->
          <!--                            {{data.item.dollarDeals.toLocaleString()}}-->
          <!--                        </span>-->
          <!--                    </template>-->

          <!--                    <template #cell(tomanDeals)="data">-->
          <!--                        <span dir="ltr">-->
          <!--                            {{data.item.tomanDeals.toLocaleString()}}-->
          <!--                        </span>-->
          <!--                    </template>-->

          <!--        <template #cell(createdAtDateTime)="data">-->
          <!--          <span dir="ltr">-->
          <!--            {{ $G2J(data.item.createdAtDateTime) }}-->
          <!--          </span>-->
          <!--        </template>-->

          <template #cell(changedPercent)="data">
            <b-badge
              pill
              :variant="'light-'+changedPercentVariant(data.item.changedPercent)"
              class="text-capitalize"
              dir="ltr"
            >
              {{ Math.abs(data.item.changedPercent)+'%' }}
            </b-badge>
            <feather-icon
              :icon="changedPercentIcon(data.item.changedPercent)"
              size="18"
              class="ml-50"
              :class="`text-${changedPercentVariant(data.item.changedPercent)}`"
            />
          </template>

        <!--        <template #cell(action)="{index}">-->
        <!--          <template-->
        <!--            v-if="$havePermission('LIST_ORDERS') ||-->
        <!--              $havePermission('LIST_TRADES')"-->
        <!--          >-->
        <!--            <b-dropdown-->
        <!--              id="dropdown-offset"-->
        <!--              variant="link"-->
        <!--              no-caret-->
        <!--              offset="80px"-->
        <!--              :right="true"-->
        <!--            >-->
        <!--              <template #button-content>-->
        <!--                <feather-icon-->
        <!--                  icon="MoreVerticalIcon"-->
        <!--                  size="16"-->
        <!--                  class="align-middle text-body"-->
        <!--                />-->
        <!--              </template>-->

        <!--              <b-dropdown-item-->
        <!--                v-if="$havePermission('LIST_ORDERS')"-->
        <!--                :to="{ name: 'Orders', query: { dollar_id: index+1 } }"-->
        <!--              >-->
        <!--                <feather-icon-->
        <!--                  icon="ShoppingBagIcon"-->
        <!--                  :badge="5"-->
        <!--                  badge-classes="badge-info test-badge"-->
        <!--                />-->
        <!--                <span class="align-middle ml-50">سفارشات</span>-->
        <!--              </b-dropdown-item>-->

        <!--              <b-dropdown-item-->
        <!--                v-if="$havePermission('LIST_TRADES')"-->
        <!--                :to="{ name: 'Deals', query: { dollar_id: index+1 } }"-->
        <!--              >-->
        <!--                <feather-icon-->
        <!--                  icon="BriefcaseIcon"-->
        <!--                  :badge="5"-->
        <!--                  badge-classes="badge-info test-badge"-->
        <!--                />-->
        <!--                <span class="align-middle ml-50">معاملات</span>-->
        <!--              </b-dropdown-item>-->
        <!--            </b-dropdown>-->
        <!--          </template>-->
        <!--          <not-allowed v-else />-->
        <!--        </template>-->
        </b-table>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BOverlay,
  BButton,
  BTable,
  BForm,
  BFormGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'DollarPrice',
  components: {
    BCard,
    BOverlay,
    BButton,
    BTable,
    BForm,
    BFormGroup,
    Cleave,
  },
  data() {
    return {
      loading: false,
      items: [],
      new_price_modal: false,
      new_price: null,
      columns: [
        {
          label: 'قیمت واحد',
          key: 'price',
          sortable: true,
          searchType: 'number',
        },
        // {
        //     label: 'معاملات انجام شده (دلار)',
        //     key: 'dollarDeals',
        //     sortable: true,
        //     searchType: 'number'
        // },
        // {
        //     label: 'معاملات انجام شده (تومان)',
        //     key: 'tomanDeals',
        //     sortable: true,
        //     searchType: 'number'
        // },
        {
          label: 'تاریخ ثبت قیمت',
          key: 'created_at',
          sortable: true,
          searchType: 'date',
        },
        // {
        //   label: 'تغییر',
        //   key: 'changedPercent',
        //   sortable: true,
        //   searchType: 'number',
        // },
        // {
        //     label: 'جزییات',
        //     key: 'action'
        // },
      ],
    }
  },
  created() {
    this.getDolors()
  },
  methods: {
    async getDolors() {
      const response = await this.$axios('/currency')
      this.items = response.data.data
    },
    // eslint-disable-next-line consistent-return
    async submit(price) {
      const priceData = price.replace(/,/ig, '')
      if (!priceData) {
        console.log('empty')
        return false
      }
      this.loading = true
      await this.$axios({
        url: '/currency',
        method: 'POST',
        data: {
          price: priceData,
        },
      })
      this.getDolors()
      this.loading = false
      this.new_price_modal = false
    },
  },
}
</script>

<style scoped>

</style>
